import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "security",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#security",
        "aria-label": "security permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Security`}</h2>
    <p>{`Tyro Health stores information necessary for providing our services and only for the period required to meet operational or regulatory responsibilities.Data is processed and stored on cloud infrastructure exclusively hosted in Australia. `}<br /></p>
    <p>{`Health account information is protected through an advanced tokenisation and encryption system, similar to that employed by leading banks and payment processors.`}<br /></p>
    <p>{`Sensitive information is encrypted end to end through strong TLS ciphers which provide protection beyond SSL. Our hosting partners abide by best practice security frameworks including ISO 27001, Australian IRAP, SOC 2, SOC 3 and PCI DSS.Our platform enforces technical controls to help prevent abuse, fraudulent transactions and unauthorised disclosure of data.`}<br /></p>
    <p>{`Our systems are regularly tested by independent security experts and have undergone multiple external reviews including regular vulnerability assessments, full source code reviews, penetration tests, security control and data privacy reviews.`}<br /></p>
    <p>{`Where a data breach is reported impacted parties will be notified directly or indirectly via a notice on our website. `}<br /></p>
    <p>{`Tyro health  will impose velocity controls to prevent wholesale enumeration Worksafe Victoria account details. If an excessive number of calls are made in a period, Tyro health or Worksafe Victoria  may suspend your account.  `}<br /></p>
    <p>{`The Tyro Health  API key is a highly privileged account token - it should never be displayed or made available for any patient/client facing requests or responses. `}<br /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      